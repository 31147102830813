import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import ReactGA from 'react-ga';
import Page from '@components/page';
import IntroModal from '@components/intro-modal';
import { Colors, Fonts } from '@styles';
import Assets from '@assets';

const LandingContainer = styled.div`
  width: 75vw;
  height: 75vh;
  padding: 1.5%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background: ${Colors.white};
  border-radius: 34px;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 85vw;
  }
`;

const ContentWrapper = styled.div`
  overflow: auto;
  padding: 0 10%;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${Colors.scrollbarTrack};
    border-radius: 10px;
    margin: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${Colors.primary};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  font-size: 1.7rem;
  color: ${Colors.primary};
  font-family: ${Fonts.bold};
  margin-bottom: 12px;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 1.5rem;
  }
`;

const Text = styled.div`
  font-size: 1rem;
  line-height: 1.9rem;
  color: ${Colors.darkGrey};
  font-weight: 100;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ separation }) => (separation ? '15px' : 0)};

  strong {
    font-family: ${Fonts.bold};
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    font-size: 0.9rem;
    line-height: 1.6rem;
  }
`;

const Link = styled.a`
  text-decoration: none;
  font-family: ${({ strong }) => (strong ? Fonts.bold : Fonts.regular)};
  font-size: ${({ strong }) => (strong ? '1.2rem' : '1rem')};
  color: ${Colors.orange};

  &:hover {
    text-decoration: underline;
  }
`;

const SponsorsWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: -18px;
  height: 73px;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    bottom: 5px;
  }
`;

const SponsorsLeftWrapper = styled.div`
  position: absolute;
  left: -7%;
  display: flex;
  align-items: center;
  width: 20%;
  justify-content: space-between;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 30%;
  }
`;

const SponsorsRightWrapper = styled.div`
  position: absolute;
  right: -7%;
  display: flex;
  align-items: center;
  width: 20%;
  justify-content: space-between;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    width: 30%;
  }
`;

const Sponsor = styled.img``;

function iOS() {
  if (typeof navigator !== 'undefined') {
    return (
      [ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod' ].includes(
        navigator.platform
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
  return false;
}

const Landing = () => {
  const pageTitle = 'Landing';

  const [ showIntroModal, setShowIntroModal ] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('session-id')) {
      const landingIntro = window.localStorage.getItem('landing-intro');
      if (!landingIntro || landingIntro != 1) {
        setShowIntroModal(true);
      }

      let steps = window.localStorage.getItem('completed-steps');
      if (steps) {
        steps = JSON.parse(steps);

        if (steps.landing === true) {
          setShowIntroModal(false);
        } else {
          steps.landing = true;
          window.localStorage.setItem('completed-steps', JSON.stringify(steps));
        }
      }
    } else {
      navigate('/intro');
    }
  }, []);

  const handleIntroModalOnClose = () => {
    setShowIntroModal(false);

    window.localStorage.setItem('landing-intro', 1);
  };

  const handleOnClickSurveyLink = () => {
    ReactGA.event({
      category: 'Survey',
      action: 'User clicked survey link',
    });
  };

  return (
    <Page
      layout={iOS() ? '12% 3% auto' : null}
      title={pageTitle}
      icon={Assets.images.PageIconLanding}
      audio={Assets.audios.PageAudioLanding}
      withNavigation={false}
      fullContent
    >
      <LandingContainer>
        <ContentWrapper>
          <Content>
            <Title>Link to Survey</Title>
            <Text>
              <Link
                href="https://studies.fccc.edu/redcap/surveys/?s=MN4R33MLDF"
                strong
                onClick={handleOnClickSurveyLink}
              >
                https://studies.fccc.edu/redcap/surveys/?s=MN4R33MLDF
              </Link>
            </Text>
            <SponsorsWrapper>
              <SponsorsLeftWrapper>
                <Sponsor src={Assets.images.Sponsor1} />
                <Sponsor src={Assets.images.Sponsor2} />
              </SponsorsLeftWrapper>
              <SponsorsRightWrapper>
                <Sponsor src={Assets.images.Sponsor3} />
                <Sponsor src={Assets.images.Sponsor4} />
              </SponsorsRightWrapper>
            </SponsorsWrapper>
          </Content>
          <Content>
            <Title>Resources</Title>
            <Text separation>
              <strong>Information on genetic and genomic testing from the National Cancer Institute</strong>
              <Link
                href="https://www.cancer.gov/about-cancer/treatment/types/precision-medicine/tumor-dna-sequencing"
                target="_blank"
              >
                https://www.cancer.gov/about-cancer/treatment/types/precision-medicine/tumor-dna-sequencing
              </Link>
            </Text>
            <Text separation>
              <strong>
                Information on genetic and genomic testing from the Centers for Disease Control and Prevention
              </strong>
              <Link href="https://www.cdc.gov/genomics/gtesting/genetic_testing.htm" target="_blank">
                https://www.cdc.gov/genomics/gtesting/genetic_testing.htm
              </Link>
            </Text>
            <Text>
              <strong>Information on genetic and genomic testing from the National Cancer Institute</strong>
              <Link
                href="https://www.cancerresearch.org/events/webinars/genomics-genetic-testing-cancer-immunotherapy"
                target="_blank"
              >
                https://www.cancerresearch.org/events/webinars/genomics-genetic-testing-cancer-immunotherapy
              </Link>
            </Text>
          </Content>
          <Content>
            <Title>Gene Pilot</Title>
            <Text>Developed by Sarah Bauerle Bass, PhD, MPH & Michael Hall, MD, MS – Co-Principal Investigators</Text>
            <Text>Produced by Dogtown Media</Text>
            <Text>Funded by the American Cancer Society</Text>
          </Content>
          <Content>
            <Title>Acknowledgements</Title>
            <Text>
              Thank you to all the patients who have shared their stories and insights and to the staff and students who
              have brought their talents to the development and testing process.
            </Text>
          </Content>
        </ContentWrapper>
      </LandingContainer>
      {showIntroModal ? (
        <IntroModal
          icon={Assets.images.PageIntroIconLanding}
          audio={Assets.audios.PageAudioLanding}
          onClose={handleIntroModalOnClose}
        />
      ) : null}
    </Page>
  );
};

export default Landing;
